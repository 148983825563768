<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent :title="`${$route.params.matchType === 'matched' ? '매칭완료' : '매칭 진행중'} 목록`" />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <v-select
          v-model="filterLocation"
          :items="locations"
          label="지역/필터"
          append-icon="expand_more"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table
          class="tbl elevation-2"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="item in headers"
                  :key="item.text"
                  :class="`text-${item.align}`"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender
              :is-loading="isLoading"
              :cols="headers.length"
              :items-length="items.length"
            />
            <tbody v-if="!isLoading">
              <tr
                v-for="item in items"
                :key="`patient-item-${item.extra_info.idx}`"
              >
                <td class="text-center">
                  <router-link :to="`/patients/${item.extra_info.idx}`">
                    {{ item.extra_info.idx }}
                  </router-link>
                </td>
                <td class="text-center">
                  <router-link :to="`/patients/${item.extra_info.idx}`">
                    {{ item.basic_info.name }}
                  </router-link>
                </td>
                <td class="py-2">
                  <v-chip
                    small
                    outlined
                    class="mr-2"
                    :color="(item.basic_info.gender === 'male') ? 'blue' : 'red'"
                  >
                    {{ item.basic_info.getGender() }}, {{ item.basic_info.age }}세, {{ item.extra_info.weight }}Kg
                  </v-chip>
                  <v-chip
                    outlined
                    small
                  >
                    <Promised
                      :promise="item.extra_info.getAddress()"
                    >
                      <template v-slot:combined="{ data }">
                        {{ data }}
                      </template>
                    </Promised>
                  </v-chip>
                  <br>
                  <v-chip
                    label
                    small
                    class="mt-2"
                  >
                    {{ item.extra_info.medical_information }}
                  </v-chip>
                </td>
                <td class="py-2">
                  <v-chip
                    label
                    small
                    class="mr-2"
                  >
                    {{ item.extra_info.more_information }}
                  </v-chip>
                  <v-chip
                    label
                    small
                    class="mr-2"
                  >
                    {{ item.extra_info.getWantCarerGender() }}
                  </v-chip>
                  <div class="mt-2">
                    <v-chip
                      label
                      small
                      class="mr-2"
                    >
                      {{ item.extra_info.started_at }} ~ {{ item.extra_info.ended_at }}
                    </v-chip>
                    <v-chip
                      label
                      small
                      class="mr-2"
                    >
                      {{ item.extra_info.care_time_info }}, {{ item.extra_info.daily_payment }}
                    </v-chip>
                  </div>
                </td>
                <td class="text-center">
                  {{ item.extra_info.is_matched == 1 ? 'Y' : 'N' }}
                </td>
                <td class="text-center">
                  {{ item.extra_info.is_deleted == 1 ? 'Y' : 'N' }}
                </td>
                <td class="text-center">
                  {{ item.extra_info.created_at | dayjsLocalDatetime }}
                  <Promised
                    v-show="item.extra_info.is_matched === 1"
                    :promise="item.extra_info.getMatchedInfo()"
                  >
                    <template v-slot:combined="{ data }">
                      <div>
                        {{ data | dayjsLocalDatetime }}
                      </div>
                    </template>
                  </Promised>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <PaginationComponent v-model="model.pagination" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PaginationComponent from '../_elements/pagination/Pagination'
import PageTitleComponent from '../_elements/title/PageTitle'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import PatientList from '../patients/model/patientList'

const locations = [
  { text: '전체', value: null },
  { text: '서울', value: 2 },
  { text: '부산', value: 3 },
  { text: '대구', value: 4 },
  { text: '인천', value: 5 },
  { text: '광주', value: 6 },
  { text: '대전', value: 7 },
  { text: '울산', value: 8 },
  { text: '세종', value: 9 },
  { text: '경기', value: 10 },
  { text: '강원', value: 11 },
  { text: '충북', value: 12 },
  { text: '충남', value: 13 },
  { text: '전북', value: 14 },
  { text: '전남', value: 15 },
  { text: '경북', value: 16 },
  { text: '경남', value: 17 },
  { text: '제주', value: 18 }
]

export default {
  components: {
    PageTitleComponent,
    PaginationComponent,
    TbodyPreRender
  },
  data() {
    return {
      model: new PatientList(),
      isLoading: false,
      query: '',
      items: [],
      headers: [
        { text: 'Idx', align: 'center' },
        { text: '환자명', align: 'center' },
        { text: '환자정보', align: 'center' },
        { text: '요청정보', align: 'center' },
        { text: '매칭여부', align: 'center' },
        { text: '삭제여부', align: 'center' },
        { text: this.createdAtHeader(), align: 'center' }
      ],
      locations,
      filterLocation: null
    }
  },
  watch: {
    filterLocation() {
      this.getList()
    }
  },
  created() {
    const routeQuery = this.$route.query
    const { matchType } = this.$route.params
    this.model.addfilter('match', (matchType === 'matched') ? 'done' : 'apply')

    if (routeQuery.page === undefined) {
      this.$router.push({ query: { page: 1 } }, () => {})
    } else {
      this.query = routeQuery.query || ''
      this.model.pagination.page = parseInt(routeQuery.page, 10) || 1
      this.getList()
    }
  },
  methods: {
    createdAtHeader() {
      let header = '등록일시'
      if (this.$route.params.matchType === 'matched') {
        header += '/ 매칭일시'
      }
      return header
    },
    async getList() {
      this.isLoading = true
      const { matchType } = this.$route.params
      if (this.filterLocation) {
        this.model.filters = {
          location: this.filterLocation,
          match: matchType === 'matched' ? 'done' : 'apply'
        }
      } else {
        this.model.filters = {
          match: matchType === 'matched' ? 'done' : 'apply'
        }
      }

      const { items, pagination } = await this.model.getList()
      if (items) {
        this.items = items
        this.model.pagination = { ...pagination } // vue 2 not catch deep object
      }
      this.isLoading = false
    },
    async getAddress(item) {
      const address = await item.extra_info.getAddress()
      // console.log(address)
      return address
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
